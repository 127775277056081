import React from 'react';

import { authenticationService } from '../services/authentication.service';
import PersonalDetails from '../components/PersonalDetails';

class AccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
        };
    }

    componentDidMount() {
        
    }

    render() {
        const { currentUser } = this.state;
        return (
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    <div className="sign_info">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <h1>Hi {currentUser.fname}!</h1>
                                <PersonalDetails />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export { AccountPage };