import { BehaviorSubject } from 'rxjs';

import { handleResponse } from '../helpers/handleResponse';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    register,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {
    return fetch('https://api.membership.community/auth/login', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            'email': email, 
            'password': password 
        })
    })
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
    }).catch(error => {
        return Promise.reject(error);
    });
}

function register(fname, lname, email, password) {
    return fetch('https://api.membership.community/auth/register', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            'fname': fname,
            'lname': lname,
            'email': email, 
            'password': password 
        })
    })
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
    }).catch(error => {
        return Promise.reject(error);
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}