import React from 'react';

import { authenticationService } from '../services/authentication.service';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';

class PersonalDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
        };
    }

    componentDidMount() {
        
    }

    render() {
        const { currentUser } = this.state;
        return (
            <div>
                <p>Username: {this.state.currentUser.username}</p>
                <p>First Name: {this.state.currentUser.fname}</p>
                <p>Last Name: {this.state.currentUser.lname}</p>
                <Elements stripe={stripePromise}>
                    <InjectedCheckoutForm />
                </Elements>
            </div>
        );
    }
}

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
  
  const stripePromise = loadStripe('pk_test_PCwA91nGkwDu9NQ33JrJ4xp0');

export default PersonalDetails;