import { authenticationService } from '../services/authentication.service';

export function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);       
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload(); 
            }

            const error = "Sorry, there was an unknown API error.";
            return Promise.reject(error);
        }
        else {
            if(data.message !== "success") {                               
                let error = (data && data.password) || "Sorry, there was an unknown error.";
                error = (data && data.email) || error; 
                return Promise.reject(error);
            }
            else {
                let user = {
                    id: data.user.id,
                    username: data.user.email,
                    fname: data.user.fname,
                    lname: data.user.lname,
                    token: data.access_token
                };
                return user; 
            }
        }                
    });
}