import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar';
import { history } from './helpers/history';
import { authenticationService } from './services/authentication.service';
import { PrivateRoute } from './components/PrivateRoute';
import { Home } from './pages/Home';
import { AccountPage } from './pages/AccountPage';
import { LoginPage } from './pages/LoginPage';
import FooterTwo from './components/Footer/FooterTwo';
import FooterData from './components/Footer/FooterData';
import NotFound from "./pages/404";
import { SignUp } from './pages/SignUp';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }

    logout() {
        authenticationService.logout();
        history.push('/login');
    }

    componentDidMount() {
      this.props.hideLoader();
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }    

    render() {
        return (
            <Router history={history}>
              <div className="body_wrapper">
                <CustomNavbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus" currentUser={this.state.currentUser} logout={this.logout} />
 
                    <Switch>
                        <PrivateRoute exact path="/account" component={AccountPage} />
                        <Route exact path="/login" component={LoginPage} />  
                        <Route exact path="/register" component={SignUp} /> 
                        <Route exact path="/" component={Home} /> 
                        <Route exact path="/logout" component={Home} /> 
                        <Route component={NotFound} />
                    </Switch>                                   

                  <FooterTwo FooterData={FooterData}/>    
                </div>              
            </Router>
        );
    }
}

export default App;