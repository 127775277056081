import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../services/authentication.service';

class LoginPage extends Component {
    render() {
        return(
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    <div className="sign_info">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="sign_info_content">
                                    <h3 className="f_p f_600 f_size_24 t_color3 mb_40">First time here?</h3>
                                    <h2 className="f_p f_400 f_size_30 mb-30">Join now and get<br/>access to our<br /><span className="f_700">amazing</span> platform</h2>
                                    <ul className="list-unstyled mb-0">
                                        <li><i className="ti-check"></i> Secure your platform name</li>
                                        <li><i className="ti-check"></i> Setup your platform</li>
                                        <li><i className="ti-check"></i> View Statistics</li>
                                    </ul>
                                    <Link to="/register" className="btn_three sign_btn_transparent">Sign Up</Link>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="login_info">
                                    <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Sign In</h2>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().required('Email is required'),
                                            password: Yup.string().required('Password is required')
                                        })}
                                        onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            authenticationService.login(email, password)
                                                .then(
                                                    user => {
                                                        const { from } = this.props.location.state || { from: { pathname: "/account" } };
                                                        this.props.history.push(from);
                                                    },
                                                    error => {
                                                        setSubmitting(false);
                                                        setStatus(error);
                                                    }
                                                );
                                        }}>
                                        {({ errors, status, touched, isSubmitting }) => (   
                                            <Form className="login-form sign-in-form">
                                                <div className="form-group text_box">
                                                    <label htmlFor="email">Email</label>
                                                    <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group text_box">
                                                    <label htmlFor="password">Password</label>
                                                    <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="extra mb_20">
                                                    <div className="checkbox remember">
                                                        <label>
                                                            <input type="checkbox"/> Keep me Signed in
                                                        </label>
                                                    </div>
                                                
                                                    <div className="forgotten-password">
                                                        <a href="/#">Forgot Password?</a>
                                                    </div>
                                                </div>
                                                {status &&
                                                    <div className={'alert alert-danger'}>{status}</div>
                                                }
                                                <div className="form-group">
                                                    <button type="submit" className="btn_three" disabled={isSubmitting}>Login</button>
                                                    {isSubmitting &&
                                                        <img alt="Loading" className="ml-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>                                                
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export { LoginPage };