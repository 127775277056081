import React, {Component} from 'react';
import HRServiceItems from './HRServiceItems';
import { Link } from 'react-router-dom';

class HRService extends Component{
    constructor(){
        super();
        this.state= {
            error: null,
            isLoaded: false,
            services: []
        }
    }

    componentDidMount() {
        fetch("https://api.membership.community/home/get_service_details")
        .then(res => res.json())
        .then((result) => {
            this.setState({
                isLoaded: true,
                services: result
            });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            this.setState({
                isLoaded: true,
                error
            });
            }
        )
    }

    render(){
        return(
            <section className="prototype_service_info">
                <div className="symbols-pulse active">
                    <div className="pulse-1"></div>
                    <div className="pulse-2"></div>
                    <div className="pulse-3"></div>
                    <div className="pulse-4"></div>
                    <div className="pulse-x"></div>
                </div>
                <div className="container">
                    <h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb_90">Root to Crown is built for creators like you.<br/> With useful features, an intuitive interface, and frequent updates to support your growth.</h2>
                    <div className="row p_service_info">
                            {
                                this.state.services.map(item => {
                                    return(
                                        <HRServiceItems HRtitle={item.HRtitles} HRdescription={item.HRdescription}  Hicon={item.Hicon} rclass={item.rclass} iclass={item.iclass} key={item.id}/>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="action_btn text-center mt_60">
                                <Link to="/register" className="btn_hover agency_banner_btn wow fadeInLeft" data-wow-delay="0.5s">Sign Up Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HRService;