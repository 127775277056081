import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Sticky from 'react-stickynode'; 
import { authenticationService } from '../services/authentication.service';
import { history } from '../helpers/history';

class CustomNavbar extends Component {

    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass, currentUser, logout} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/root_to_crown_logo.png")} alt=""/>
                            <img src={require("../img/root_to_crown_logo.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                
                            </ul>  
                            {currentUser ?
                                <Link to="/account" className={`btn_get btn_hover ${hbtnClass}`}>Account</Link> :
                                <Link to="/login" className={`btn_get btn_hover ${hbtnClass}`}>Login</Link>
                            }   
                            {currentUser ? 
                                <Link to="/login" onClick={logout} className={`btn_get btn_hover ${hbtnClass}`}>Logout</Link>:
                                <Link to="/register" className={`btn_get btn_hover ${hbtnClass}`}>Sign Up Now</Link>
                            }                        
                            
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;