import React from 'react';
import AgencyBanner from '../components/Banner/AgencyBanner';
import BannerData from '../components/Banner/BannerData';

import HRService from '../components/Service/HRService';

const Home = () => {
    return(
        <>
        <AgencyBanner BannerData={BannerData}/>
        <HRService />
        </>
    )
}
export { Home };