const BannerData = {
    AgencyText : [
        {
            id: 1,
            btitle : 'The Root to Crown Membership Community',
            description : 'A subscription based platform for publishing spiritually aligned tools, methods and techniques.',
        }
    ],
    ClientsLogo: [
        {
            id : 1,
            image: 'logo_01.png'
        },
        {
            id : 2,
            image: 'logo_02.png'
        },
        {
            id : 3,
            image: 'logo_03.png',
        },
        {
            id : 4,
            image: 'logo_04.png',
        },
        {
            id : 5,
            image: 'logo_05.png'
        }
    ],
    AgencyTestimonial: [
        {
            id: 1,
            image: 'author_img.png',
            authorName: 'Lurch Schpellchek',
            authorPost: 'UI/UX designer',
            description: 'What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it`s all gone to pot buggered.',
        },
        {
            id: 2,
            image: 'author_img.png',
            authorName: 'Lurch Schpellchek',
            authorPost: 'UI/UX designer',
            description: 'What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it`s all gone to pot buggered.',
        }
    ]
}
export default BannerData;