import React , {Component} from 'react';
class FooterTwo extends Component {
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>            
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <p className="mb-0 f_400" dangerouslySetInnerHTML={{__html: FooterData.copywrite}}></p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <ul className="list-unstyled f_menu text-right">
                                    <li><a href=".#">Terms of Use</a></li>
                                    <li><a href=".#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;